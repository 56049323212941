import {signal, WritableSignal} from "@angular/core";

export class WritableSignalMap<T=any> {

    private map = new Map<string,WritableSignal<T|undefined>>;

    get(name: string): WritableSignal<T|undefined> {
        if (!this.map.has(name))
            this.map.set(name, signal<T|undefined>(undefined));
        return this.map.get(name)!;
    }

    set(name: string, value: T|undefined) {
        if (!this.map.has(name))
            this.map.set(name, signal<T|undefined>(value));
        else
            this.map.get(name)!.set(value);
    }

    delete(name: string): boolean {
        // First set the signal to undefined (to let any watchers be notified of their disappearance)
        if (this.map.has(name)) {
            this.map.get(name)!.set(undefined);
            return true;
        }
        // Do NOT remove this signal form the map (by calling map.delete), because someone (like a computed signal)
        // may be holding on to a retrieved signal for the key, and if it's set again later it will create a different
        // signal instance (and store it in the map). If we keep it around with undefined value it will be reused
        // correctly!
        return false;
    }

    clear() {
        // First set all signal values to undefined, so any changes dependent on them can be triggered.
        this.map.forEach((sig) => {
            sig.set(undefined);
        });

        // Do NOT remove all signals from the map (by calling map.clear) now, because someone (like a computed calculation)
        // may be holding on to a retrieved signal for a key, and if it's set again later it will create a different
        // instance (and store it in the map). If we keep them around with undefined values they will be reused
        // correctly!
    }

}